<template>
  <div ref="outer" tabindex="0" class="focus:outline-none">
    <h3 class="text-white text-2xl font-semibold">
      {{ question.text }}
    </h3>

    <!-- Question is multiple choice -->
    <fieldset v-if="question.options">
      <ul class="mt-6 space-y-4">
        <li tabindex="0"
            v-for="(choice, i) in question.options"
            :key="i"
            class="group relative bg-gray-900 rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-indigo-800 focus:ring-indigo-500"
            @click="input = i"
            @keyup.enter="input === i ? submit() : input = i"
            @keyup.space="input = i"
            @keyup.up="selectPrev($event, i)"
            @keyup.down="selectNext($event, i)">
          <div class="rounded-lg border-2 border-gray-500 bg-gray-800 px-4 py-3 hover:border-gray-400 flex pointer-events-none">
            <div class="flex items-center h-6">
              <input :id="`question-${index}-${i}`"
                     tabindex="-1"
                     name="privacy_setting"
                     type="radio"
                     class="bg-gray-700 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300"
                     :checked="input === i">
            </div>
            <label :for="`question-${index}-${i}`" class="ml-4 flex flex-col cursor-pointer">
              <span class="block text-base font-medium"
                    :class="{ 'text-indigo-300': input === i, 'text-gray-100': input !== i }">
                {{ choice }}
              </span>
            </label>
          </div>
          <div class="absolute inset-0 rounded-lg border-2 pointer-events-none"
               :class="{ 'border-indigo-500': input === i, 'border-transparent': input !== i }"></div>
        </li>
      </ul>
    </fieldset>

    <!-- Question is text input -->
    <template v-else>
      <div class="mt-6">
        <input ref="input"
               type="text"
               name="input"
               v-model="input"
               class="text-white text-base font-medium bg-gray-600 border-0 shadow-sm focus:ring-indigo-500 focus:ring-2 block w-full rounded-md"
               :placeholder="question.placeholder"
               @keyup.enter="submit">
      </div>
    </template>

    <div class="mt-8 flex justify-center space-x-2">
      <!-- Play audio, if question has a song -->
      <button v-if="question.audio"
              class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-700"
              @click="audio.play()">
        Replay Audio
      </button>

      <button type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              :class="{ 'hover-bg-indigo-600': canSubmit, 'opacity-50 cursor-not-allowed': !canSubmit }"
              :disabled="!canSubmit"
              @click="$emit('submit', input)">
        Submit
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Question',
  props: {
    question: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  computed: {
    canSubmit() {
      return this.input !== null && this.input !== ''
    }
  },
  data() {
    return {
      input: null,
      audio: null,
      playTimeout: null,
    }
  },
  watch: {
    'question.audio': {
      immediate: true,
      handler(val) {
        if (!val) return
        this.audio = new Audio(val)
        this.audio.load()
        try {
          this.playTimeout = setTimeout(() => this.audio.play(), 1000)
        } catch (e) {
          console.error(e)
        }
      },
    },
  },
  mounted() {
    if (this.question.options) {
      this.$refs.outer.focus()
    } else {
      this.$refs.input.focus()
    }
  },
  beforeDestroy() {
    if (this.playTimeout) {
      clearTimeout(this.playTimeout)
    }
    if (this.audio) {
      this.audio.pause()
      this.audio.remove()
    }
  },
  methods: {
    submit() {
      if (this.canSubmit) {
        this.$emit('submit', this.input)
      } else {
        console.error("Can't submit")
      }
    },
    selectPrev(event, i) {
      if (event.target.previousElementSibling) {
        event.target.previousElementSibling.focus()
        this.input = i - 1
      }
    },
    selectNext(event, i) {
      if (event.target.nextElementSibling) {
        event.target.nextElementSibling.focus()
        this.input = i + 1
      }
    },
  },
}
</script>
