<template>
  <div>
    <h1 class="text-center text-white text-3xl sm:text-4xl md:text-5xl font-semibold">Mattimeter</h1>

    <label for="name" class="mt-12 block text-base font-medium text-gray-300">Name</label>
    <div class="mt-1">
      <input ref="input"
             type="text"
             name="name"
             id="name"
             v-model="name"
             class="text-white text-base font-medium bg-gray-600 border-0 shadow-sm focus:ring-indigo-500 focus:ring-2 block w-full rounded-md"
             placeholder="Joe Smith"
             @keyup.enter="start">
    </div>
    <button @click="start" type="button"
            class="mt-12 mx-auto flex items-center px-6 py-3 border border-transparent text-xl font-medium rounded text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
      Start
    </button>
  </div>
</template>
<script>
import { auth, db } from './fb'

export default {
  name: 'StartPage',
  data() {
    return {
      name: '',
    }
  },
  mounted() {
    this.$refs.input.focus()
  },
  methods: {
    async start() {
      try {
        await auth.signInAnonymously()
        await db.ref(`users/${auth.currentUser.uid}/name`).set(this.name)
        this.$emit('start', { uid: auth.currentUser.uid, name: this.name })
      } catch (e) {
        // TODO: Handle sign in error
        console.error(e)
      }
    }
  }
}
</script>
