<template>
  <div id="app" class="min-h-full flex flex-col bg-gray-800">
    <div class="flex-1"></div>
    <div class="container mx-auto p-4 sm:p-6 sm:max-w-2xl">
      <!-- Waiting for quiz to begin -->
      <div v-if="resuming || config === null"></div>

      <p v-else-if="!config.open" class="text-center text-3xl text-gray-400 font-semibold">
        This quiz is closed
      </p>

      <div v-else-if="!config.canAnswer">
        <!-- Show answer summary if quiz no longer accepting answers -->
        <AnswersSummary v-if="user" :questions="questions" :answers="answers" :user="user"/>

        <!-- Error message if not logged in -->
        <p v-else class="text-center text-3xl text-gray-400 font-semibold">
          This quiz is closed to new entries
        </p>
      </div>

      <!-- Start: user is null, waiting to sign in -->
      <StartPage v-else-if="!user" @start="start"/>

      <!-- Waiting for quiz to begin -->
      <Loading v-else-if="!config.hasStarted" text="Waiting for the quiz to start"/>

      <!-- In a question -->
      <Question v-else-if="question <= config.curQuestion" :question="questions[question]" :index="question" :key="question" @submit="submit"/>

      <!-- Waiting for next question -->
      <Loading v-else-if="question < questions.length" text="Waiting for the next question"/>

      <!-- All questions answered -->
      <AnswersSummary v-else :questions="questions" :answers="answers" :user="user"/>
    </div>
    <div class="flex-1"></div>
    <div class="flex-1"></div>
  </div>
</template>

<script>
import { db, auth } from './fb'
import StartPage from "./StartPage"
import Loading from './Loading'
import AnswersSummary from "@/AnswersSummary"
import Question from "@/Question"

export default {
  name: 'App',
  components: {
    Question,
    AnswersSummary,
    StartPage,
    Loading,
  },
  data() {
    return {
      auth,
      config: null,
      user: null,
      question: 0,
      questions: [],
      answers: [],
      resuming: false,
    }
  },
  firebase: {
    config: db.ref('config'),
    questions: db.ref('questions'),
  },
  async beforeMount() {
    if (auth.currentUser) {
      this.resuming = true
      try {
        await this.$rtdbBind('answers', db.ref(`users/${auth.currentUser.uid}/answers`))
        this.user = {name: '', uid: auth.currentUser.uid}
        this.question = this.answers.length
        this.resuming = false
      } catch (e) {
        console.error(e)
      }
    }
  },
  methods: {
    start(user) {
      this.user = user
      this.$rtdbBind('answers', db.ref(`users/${user.uid}/answers`))
    },
    async submit(value) {
      try {
        await db.ref(`users/${this.user.uid}/answers/${this.question}/value`).set(value)
        this.question++
      } catch (e) {
        console.error(e)
      }
    }
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
