import Vue from 'vue'
import App from './App.vue'
import { rtdbPlugin } from 'vuefire'
import { auth } from './fb'
import './index.css'

Vue.config.productionTip = false
Vue.use(rtdbPlugin)

auth.onAuthStateChanged(() => {
  new Vue({
    render: h => h(App),
  }).$mount('#app')
})
