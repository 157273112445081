<template>
  <div>
    <h3 class="text-white text-3xl font-semibold">
      Answer Summary
    </h3>

    <ul class="mt-10 space-y-6">
      <li v-for="(question, index) in questions" :key="index">
        <p class="text-white font-medium text-lg">{{ question.text }}</p>
        <div class="bg-gray-700 border-l-4 border-gray-400 px-4 py-2 mt-2"
             :class="{ 'bg-green-700 border-green-400': index in answers && answers[index].correct, 'bg-red-800 border-red-400': index in answers && answers[index].correct === false }">
          <p v-if="index in answers && answers[index].value"
             class="text-base font-medium text-gray-100">
            <template v-if="question.options">
              {{ question.options[answers[index].value] }}
            </template>
            <template v-else>
              {{ answers[index].value }}
            </template>
          </p>
          <p v-else
             class="text-base font-medium text-gray-100 italic">
          No recorded answer
          </p>
          <p v-if="index in answers && answers[index].actual"
             class="mt-2 text-sm font-medium text-gray-100 italic">
            <strong>Correct answer:</strong>
            <template v-if="question.options">
              {{ question.options[answers[index].actual] }}
            </template>
            <template v-else>
              {{ answers[index].actual }}
            </template>
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'AnswersSummary',
  props: {
    questions: {
      type: Array,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    }
  },
}
</script>
