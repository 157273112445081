import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyCipGDOT11AD9OxaKlHmBTqlpc_wqj7-o0",
  authDomain: "mattcorp-quiz.firebaseapp.com",
  databaseURL: "https://mattcorp-quiz-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mattcorp-quiz",
  storageBucket: "mattcorp-quiz.appspot.com",
  messagingSenderId: "551696457801",
  appId: "1:551696457801:web:490f9900bceb5968cf9f98"
};

const app = firebase.initializeApp(firebaseConfig)

export const db = app.database()
export const auth = app.auth()
