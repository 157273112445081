<template>
  <div>
    <p v-if="text" class="text-center text-3xl text-gray-400 font-medium">{{ text }}</p>
    <div class="spinner">
      <div class="spinner1 w-full h-full bg-gray-200"></div>
      <div class="spinner2 w-full h-full bg-gray-200"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    }
  }
}
</script>

<style>
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 80px auto 0 auto;
}

.spinner1, .spinner2 {
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.spinner2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}
</style>